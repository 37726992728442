import React from 'react';
import { Link } from "react-router-dom";

import {
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useMediaQuery
} from '@mui/material';

// material-ui
import { useTheme } from '@mui/material/styles';

// project imports
import AuthWrapper1 from '../../components/authentication/AuthWrapper1';
import AuthCardWrapper from '../../components/authentication/AuthCardWrapper';
import AuthLogin from '../../components/authentication/auth-forms/AuthLogin';
import Logo from '../../components/Logo';
import AuthFooter from '../../components/cards/AuthFooter';



/**
 * Login Screen Component
 * @packageDocumentation
 * @module Login
 */
const Login: React.FC<{}> = () => {
  
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
   
        <AuthWrapper1>
        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh', 
                backgroundImage: `url("fondologin2.jpg")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                        <AuthCardWrapper>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item sx={{ mb: 3 }}>
                                    <Link to={"/"}>
                                        <Logo />
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction={matchDownSM ? 'column-reverse' : 'row'}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item>
                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                <Typography
                                                    color={theme.palette.secondary.main}
                                                    gutterBottom
                                                    variant={matchDownSM ? 'h3' : 'h2'}
                                                >
                                                    Hola, Bienvenido de nuevo
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    fontSize="16px"
                                                    textAlign={matchDownSM ? 'center' : 'inherit'}
                                                >
                                                    Ingresa tus credenciales
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <AuthLogin />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </AuthCardWrapper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                <AuthFooter />
            </Grid>
        </Grid>
        </AuthWrapper1>
        
      )}


export default Login;
