import moment from 'moment';
import { forEach } from 'lodash';


const PERU_TIMEZONE_OFFSET = 18000;

function localEpochTime(value: number) {
  return new Date((value + PERU_TIMEZONE_OFFSET) * 1000);
}

/**
 * To parse time hh:mm:ss format 24h to hh:mm PM/AM
 * @param time to parse fotmat hh:mm PM/AM
 * @returns time format hh:mm PM/AM
 */
function timeConvert(time: string) {
  let timeParse = '';
  if (time) {
    const timeMatch = time.match(
      /^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/,
    ) || [time];
    if (timeMatch.length > 1) {
      timeParse = moment(time, 'hh:mm A').format('h:mm A');
    }
  }
  return timeParse;
}

export function parseFormattedDateToDate(date: string): Date {
  return moment(date).toDate();
}

/**
 * To parse date to DD/MM/yyyy
 * @param date
 * @returns  format date string
 */
export function formatDate(date: moment.MomentInput, format?: string) {
  try {
    if (format) {
      return moment(date, format).format('DD/MM/yyyy');
    }
    if (date) {
      return moment(date).format('DD/MM/yyyy');
    }
    return '';
  } catch {
    return '';
  }
}

/**
 * format string
 * @param string
 * @returns  format string
 */
export function formatString(cadena: string) {
  try {
    if (cadena) {
      const cadenaArray = cadena.split(' ');
      // eslint-disable-next-line arrow-body-style
      const resultado = cadenaArray.map((item) => {
        return item.slice(0, 3);
      });
      return resultado.toString().replaceAll(',', '. ');
    }
    return '';
  } catch {
    return '';
  }
}
/**
 * duplicates elements
 * @param cadenaArray
 * @returns  format array
 */
export function removeDuplicates(element: any) {
  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }
  const unique = element.filter(onlyUnique);
  return unique;
}

/**
 * To parse date to YYYY-MM-DD
 * @param date
 * @returns  format date string
 */
export function formatDateAlternative(date: moment.MomentInput) {
  try {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    }
    return null;
  } catch {
    return null;
  }
}
export function formatDateWidthHours(date: moment.MomentInput) {
  try {
    if (date) {
      return moment(date).format('DD/MM/yyyy h:mm A');
    }
    return '';
  } catch {
    return '';
  }
}

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;
  if (text.length === 1) return text.toUpperCase;
  const lower = text.toLowerCase();
  return `${text.charAt(0).toUpperCase()}${lower.slice(1)}`;
};


const parseUpdateFormData = async (formValues: any) => {
  let keyWordsString = '';
  forEach(formValues?.keywords, (keyword) => {
    if (keyWordsString) {
      keyWordsString = `${keyWordsString},${keyword}`;
    } else {
      keyWordsString = `${keyword}`;
    }
  });
  const faqs: any = [];
  forEach(formValues?.faqs, (faq) => {
    if (faq.created) {
      faqs.push({ id: 0, pregunta: faq.pregunta });
    } else {
      faqs.push(faq);
    }
  });

  const docs: any = [];
  await Promise.all(
    formValues?.docs?.map(async (doc: any) => {
      if (doc.id) {
        docs.push(doc);
      }
    }),
  );

  const categories = formValues?.categories?.map(
    ({ areas, categorias, publico }: any) => ({
      publico: publico?.map(({ idPublico }: any) => ({ idPublico })),
      area: areas?.map(({ idArea }: any) => ({ idArea })),
      categoria: categorias?.map(({ idCategoria }: any) => ({ idCategoria })),
    }),
  );

  const result = {
    idProducto: 0,
    idTipTrap: formValues?.idTipTrap,
    nombre: formValues?.name,
    tipoDias: formValues?.tipoDias,
    descripcion: formValues?.longDesc,
    descripcionCorta: formValues?.shortDesc,
    condiciones: formValues?.conditions,
    condicionesEntrega: formValues?.deliverConditions,
    procedimiento: formValues?.procedure,
    contacto: formValues?.contact,
    plazo: formValues?.time,
    lugar: formValues?.place,
    link: formValues?.link,
    filePlantilla: formValues?.template.base64,
    filePlantillaName: formValues?.template.fileName,
    activo: formValues?.state,
    informacionAdicional: formValues?.aditionalInfo,
    palabrasClave: keyWordsString,
    consideraciones: formValues?.considerations,
    documentos: docs,
    preguntas: faqs,
    categorias: categories,
    programas: formValues?.programs,
    linkInfografia: formValues?.linkInfo,
    emailContacto: formValues?.emailContact,
  };
  return result;
};

const parseFormData = (originalData: any) => {
  let newKeyWords = [];
  if (originalData?.palabrasClave) {
    newKeyWords = originalData?.palabrasClave?.split(',');
    newKeyWords = newKeyWords?.map((keyword: any) => keyword);
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const categories = originalData?.categorias?.map((original: any) => ({
    ...original,
    publico:
      original &&
      original.publico.map(({ idPublico, publico }: any) => ({
        idPublico,
        description: publico,
      })),
    areas:
      original &&
      original.area.map(({ idArea, area }: any) => ({
        idArea,
        description: area,
      })),
    categorias:
      original &&
      original.categoria.map(({ idCategoria, categoria }: any) => ({
        idCategoria,
        description: categoria,
      })),
  }));
  const result = {
    idTipTrap: originalData?.idTipTrap,
    name: originalData?.nombre,
    tipoDias: originalData?.tipoDias,
    tiposDias: originalData?.tiposDias,
    longDesc: originalData?.descripcion,
    shortDesc: originalData?.descripcionCorta,
    procedure: originalData?.procedimiento,
    conditions: originalData?.condiciones,
    state: originalData?.activo,
    contact: originalData?.contacto,
    time: originalData?.plazo,
    place: originalData?.lugar,
    link: originalData?.link,
    template: {
      base64: originalData?.filePlantilla,
      fileName: originalData?.filePlantillaName,
    },
    savedTemplate: {
      base64: originalData?.filePlantilla,
      fileName: originalData?.filePlantillaName,
    },
    deliverConditions: originalData?.condicionesEntrega,
    aditionalInfo: originalData?.informacionAdicional,
    keywords: newKeyWords,
    faqs: originalData?.preguntas,
    considerations: originalData?.consideraciones,
    docs: originalData?.documentos,
    categories,
    price: `S/ ${parseFloat(originalData?.precio).toFixed(2)}`,
    updateDate: originalData?.fechaActualizacion
      ? moment(originalData?.fechaActualizacion).format('YYYY-MM-DD HH:mm:ss')
      : null,
    serviceCode: originalData?.codigoServicio,
    emailContact: originalData?.emailContacto,
    programs: originalData?.programas,
    linkInfo: originalData?.linkInfografia,
    inputKeyWord: originalData?.inputKeyWord,
    faqInput: '',
    editingFaq: false,
    faqPosition: originalData?.faqPosition,
    docsInput: {
      base64: originalData?.filePlantilla,
      fileName: originalData?.filePlantillaName,
    },
  };

  return result;
};

const methods = {
  localEpochTime,
  removeDuplicates,
  timeConvert,
  formatDate,
  formatDateWidthHours,
  formatString,
  formatDateAlternative,
  parseFormData,
  parseUpdateFormData,
  capitalizeFirstLetter,
};

export default methods;
