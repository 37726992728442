// material-ui
import { ButtonBase } from '@mui/material';

// project imports
const logo = require('assets/images/logo.jpg');

// ==============================|| MAIN LOGO ||============================== //
/*
<img src={String(logo)} alt="RedditusCapital" width="150" 
        />
*/
const LogoSection = () => (
    <ButtonBase disableRipple  >
       
        <img src={process.env.PUBLIC_URL + '/logoh.png'} alt="RedditusCapital" width="180" 
        />
        
    </ButtonBase>
);

export default LogoSection;
