// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" component={Link} href="https://b-creator.com" target="_blank" underline="hover">
            Developed by B-Creator
        </Typography>
        <Typography variant="subtitle1" component={Link} href="https://reddituscapital.com" target="_blank" underline="hover">
            2022 &copy; Todos los derechos reservados
        </Typography>
    </Stack>
);

export default AuthFooter;
