import { useState } from 'react';

import {AuthService} from '../../../../src/business/AuthService';

import Cookies from 'universal-cookie';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import AnimateButton from '../../../components/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import  useStyles  from './styles';
  
const cookies = new Cookies();
  /**see example in https://www.smashingmagazine.com/2020/10/react-validation-formik-yup/ for formik and yup */
  
  const SignInSchema = Yup.object().shape({
    username: Yup.string().required("*Ingrese su usuario"),
  
    password: Yup.string()
      .required("*Ingrese su contraseña")
      .min(6, "La contraseña es muy corta - Debe contener al menos 6 caracteres"),
  });
  
  
// ============================|| FIREBASE - LOGIN ||============================ //



const AuthLogin = ({ ...others }) => {
    const theme = useTheme();

    const classes = useStyles();
    
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event:any) => {
        event.preventDefault();
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                
                <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
               
            </Grid>

            <Formik
                initialValues={{
                    username: '',
                    password: '',
                    ip: '',
                    appToken: process.env.REACT_APP_APP_TOKEN,
                    submit: null
                }}
                validationSchema={SignInSchema}                 
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    
                        setSubmitting(true);
                        const loginInstance = AuthService.getInstance();
                      
                        await loginInstance.login(values)
                        .then((data:any) => {
                            if(data){
                                setStatus({ success: true });
                                setSubmitting(false);
                              
                                cookies.set('stkc', data.sessionToken, {'path': '/'});
                                cookies.set('rol', data.role, {'path': '/'});
                                cookies.set('names', data.names, {'path': '/'});
                                cookies.set('position', data.position, {'path': '/'});
                                cookies.set('roles', JSON.stringify({list: data.roles}), {'path': '/'});
                                window.location.href='/redirect';
                                
                            }
                        
                            
                        })
                        .catch((err) => {
                            setStatus({ success: false });
                            setSubmitting(false);
                            if(err.messages)
                            {
                                setErrors({ submit: err.messages[0].description });
                            }
                            else{
                                setErrors({ submit: 'Hubo un error' });;
                            }
                           
                            
                            

                        })
                       
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.username && errors.username)} sx={{ mb:"15px" }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Usuario</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="username"
                                value={values.username}
                                name="username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Usuario"
                                inputProps={{}}
                            />
                            {touched.username && errors.username && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.username}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Contraseña</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Contraseña"
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}


                        <Box sx={{ mt: 2 }}>
                        
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.btnLogin}
                                >
                                    Ingresar
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default AuthLogin;
