import React from 'react'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 import logoDark from 'assets/images/logo-dark.svg';
 import logo from 'assets/images/logo.svg';
 *
 */
 const Logo = React.forwardRef((props, ref) => (
    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="RedditsusCapital" width="200" height="auto" />
    
  ))

  Logo.displayName = "Logo";

export default Logo;
