import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';


const useStyles = makeStyles((theme: Theme) => ({
  
  btnLogin: {
    backgroundColor: theme.palette.secondary.dark+'!important',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark+'!important',
        
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
        root: {
            background: theme.palette.primary.light+'!important',
            borderRadius: '10px'+'!important',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.grey[500]+'!important',
            },
            '&:hover $notchedOutline': {
                borderColor: theme.palette.grey[700]+'!important',
            },
            '&.MuiInputBase-multiline': {
                padding: 1+'!important',
            }
        },
        input: {
            fontWeight: 500+'!important',
            background: theme.palette.primary.light+'!important',
            padding: '15.5px 14px'+'!important',
            borderRadius: '10px'+'!important',
            '&.MuiInputBase-inputSizeSmall': {
                padding: '10px 14px'+'!important',
                '&.MuiInputBase-inputAdornedStart': {
                    paddingLeft: 0+'!important',
                }
            }
        },
        inputAdornedStart: {
            paddingLeft: 4+'!important',
        },
        notchedOutline: {
            borderRadius: '10px'+'!important',
        }
    }
},
}));

export default useStyles;
