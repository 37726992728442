import React from 'react';
import Cookies from 'universal-cookie';

import {
    CircularProgress, 
    Grid
  } from '@mui/material';
  
  const cookies = new Cookies();

/**
 * Redirect Screen Component
 * @packageDocumentation
 * @module Redirect
 */
const Redirect: React.FC<{}> = () => {
    let role=cookies.get('rol');
    switch(role)
    {
        case '1': window.location.href='/backoffice/dashboard';
            break;
        case '2': window.location.href='/asesores/dashboard';
            break;
        case '3': window.location.href='/inversor/dashboard';
            break;
        default:
            window.location.href = '/inversor/dashboard';
    }

 return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <CircularProgress />
        </Grid>
      );
        
}


export default Redirect;
